// header .btn {
//     background-color: $primary;
//     color: $white;
// }
// header .btn:hover {
//     background-color: lighten($rsp, 10%);
//     color: $white;
// }
// header .btn.cta-btn {
//     color: $white;
//     border:solid 1px $white;
// }
header a.btn.country {
    font-weight: 200;
}

// Navigation Bar

header.navbar {
    background-color: $primary;
    border-bottom:solid 0px $white;
}
header a.navbar-brand {
    padding-top:0.2rem;
    padding-bottom:0.3rem;
}
header a.navbar-brand .primary.logo {
    background-image: url('/assets/img/logo/icx_logo_white.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 2rem;
    width: 10rem !important;
}
header a.navbar-brand .icon.logo {
    background-image: url('/assets/img/logo/icx_logo_white.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 2rem;
    width: 8rem;
}
header ul.navbar-nav a {
    color:$white;
    padding:0.25rem 0!important;
    margin: 0 .5rem!important;
    font-size: 0.9rem;
    border-bottom:0;
}
header ul.navbar-nav a:hover {
    color: $white;
    border-bottom:0;
}
header ul.navbar-nav li:hover>.dropdown-menu {
    display: block;
}
header ul.navbar-nav .dropdown-menu {
    background: $primary;

}

header {
  &.navbar {
    button {
      &.navbar-toggler {
        border:none;
        outline:none;
        &:focus, &:hover {
          border:none;
        }
      }
    }
  }
}

header.navbar button.navbar-toggler span.navbar-toggler-icon {
    color:$white;
    height:2rem;
    width:2rem;
}

a.nav-link:hover {
  text-decoration: underline;
}

// Mobile navigation
.navbar-collapse {
  @include media-breakpoint-only(sm) {
    border-bottom: 1px solid rgba($white,0.2);
  }
  @include media-breakpoint-only(xs) {
    border-bottom: 1px solid rgba($white,0.2);
  }
}

.navbar-collapse.collapsing ul.navbar-nav,
.navbar-collapse.show ul.navbar-nav {
  border-top: solid 4px rgba($white,1);

}

header .navbar-collapse.collapsing ul.navbar-nav a,
header .navbar-collapse.show ul.navbar-nav a {
    font-size: 1.1rem
}

header ul.navbar-nav a {
    font-weight: 200;
    font-size: 1rem;
    text-decoration: none;
}


// Footer Navigation

footer {
  a {
    display: inline-block;
    color:$secondary;
  }
}

footer .navigation {
    border-bottom:solid 1px $white;
}

footer ul.nav a,
footer ul.nav a.btn, {
  display: block;
    position: relative;
    width: 100%;
}
footer ul.nav a:hover {
    text-decoration: underline;
}

address {
    font-size: 0.9rem;
    line-height: 1.3rem;
    font-weight: 200;
}
