.btn {
  border-radius:0;
  border-width: 0px;
  border-style: solid;
  padding: 1rem 2rem;
  text-align: center;
  white-space: normal;
  vertical-align: middle;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.125rem;
  touch-action: manipulation;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-transition: opacity .15s linear;
  transition: opacity .15s linear;
  -ms-touch-action: manipulation;
  user-select: none;
  display: inline-block;
  margin-bottom: 0;
  color: $secondary !important;
  text-transform: uppercase;
  &:focus, &:hover, &:active {
    box-shadow:0 0 0 0 !important;
  }
  &.fixed-bottom {
    bottom:25px;
    max-width:90%;
  }
}
.btn-cta {
  background: linear-gradient(to bottom,$cta,$cta);
  border: solid 4px $white;
  border-top: solid 4px $white;
  border-bottom: solid 4px $white;
  width:100%;
  max-width:250px;
  &:hover {
    background: linear-gradient(to bottom,lighten($cta, 10%),lighten($cta, 10%));
  }
  &#next-btn {
    max-width:none !important;
    width:auto !important;
  }
}

.btn-cta-alt {
  background: $primary;
  border: solid 4px $white;
  border-top: solid 4px $white;
  border-bottom: solid 4px $white;
  color:$white !important;
  &:hover {
    background: linear-gradient(to bottom,lighten($primary, 10%),lighten($primary, 10%));
  }
}

.btn-sm {
  font-size:1.025rem;
  padding: .5rem 1.2rem;
}

.navbar {
  .btn-sm:hover {
    text-decoration: underline;
    &.btn-outline-light {
      text-decoration:none;
    }
  }
}

a.btn.btn-primary {
    color: $white;
}
a.btn.btn-rsp-red {
    color: $white;
}
a.btn-link {
    padding: 0;
}
.btn-white {
    color: $primary;
}
a.btn.btn-xs {
    font-size: 0.8rem;
    padding: 0.2rem 0.8rem;
}

.btn-outline-dark {
  border:3px solid black;
  border-color:$primary;
  &:hover {
    color: white !important;
    background-color: $primary;
  }
}

.btn-outline-light {
  border:2px solid white;
  color: white !important;
  &:hover {
    background-color: $cta;
    color:$primary !important;
  }
}


