::placeholder {
  color: #c1c1c1 !important;
}
:-ms-input-placeholder {
  color: #c1c1c1 !important;
}
::-ms-input-placeholder {
  color: #c1c1c1 !important;
}
.feedback {
  font-size: 80%;
  margin-top: 0.5rem;
}
