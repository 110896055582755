
html,body {
   background: $primary;
   scroll-behavior: smooth;
}

.wrap.white hr {
    border-color: $gray-200;
}

.wrap.gray hr {
    border-color: $gray-400;
}

// Wrappers
.wrap.dark {
@include gradient;
  color: $white;
  h2 {
    color: $white;
  }
}

.wrap.dark a {
  color: $white;
}

.wrap {
  &.white {
    background: $white;
    color: $secondary;
  }
}

.wrap.gray {
  background: $gray-200;
}

.wrap.gray,
.wrap.gray a {
  color: $primary;
}

.wrap.light {
  background: $bg;
}

.wrap.light,
.wrap.light a {
  color: $secondary;
}

.wrap.danger {
  border-top: solid 1px $white;
  background: $cta;
  color: $white;
}

.bg-dark {
  border-top:10 px solid $cta;
  background-color: $primary !important;
}

.panel,
form {
  font-size:1.125rem;
}
form,
.panel,
form a,
.panel a {
    color: $primary!important;
}
form input.form-control::placeholder {
    color: $gray-500;
}
form input.form-control,
form select.form-control,
form textarea.form-control {
    box-shadow: none;
}

.white {
  background: $white;
}

.light-blue {
  background: $light-blue;
}

.border {
  border: 4px solid $white !important;
}


.border-dark {
  border: 1px solid $primary !important;
}

.line {
  border-top: 10px solid $black;
  padding-top: 1.5rem;
  width:150px;
  display: inline-block;
}

.nav {
  &.nav-pills {
    @include media-breakpoint-only(xs) {
      border-left:10px solid white;
    }
    @include media-breakpoint-only(sm) {
      border-left:10px solid white;
    }
  }
}

.nav-pills {
  .nav-link {
    border-radius: 0;
    background-color: $bg;
    @include media-breakpoint-only(md) {
      background-color: $bg;
    }
    @include media-breakpoint-only(sm) {
      background-color: $bg;
    }
    &.active {
      color: $primary;
      background-color: $white;
    }
  }
}

.nav.nav-pills {
  .nav-item {
    padding-right:0px;
    padding-bottom:.5rem;
    @include media-breakpoint-only(xl) {
      padding-right:1rem !important;
      padding-bottom:0;
    }
    @include media-breakpoint-only(lg) {
      padding-right:1rem;
      padding-bottom:0;
    }
    @include media-breakpoint-only(md) {
      padding-right:.5rem;
      padding-bottom:0;
    }
  }
}

.rounded-top {
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
}

.affix {
  top:20px !important;
  position: fixed !important;
}

.affix-bottom {
  position: relative !important;
}

.carousel-item.active {
    display:block;
}

.carousel-fade .carousel-item {
	opacity: 0;
	transition-duration: 2s;
	transition-property: opacity;
}

// Disable Valid form styles
.was-validated .form-control:valid, .form-control.is-valid {
background-image:none;
}

.invalid-feedback {
  color: black;
}

form {
  label {
    font-weight:600;
    text-transform: uppercase;
    font-size:1rem;
  }
}


#course-info .carousel-item {

}
#course-info .carousel-inner {
    color: $white;
}

/* ##### Carousel ##### */
#hero {
  .carousel-caption {
    position: inherit;
    top: 0;
    right: auto;
    left: auto;
    width: 100%;
    height: 100%;
    z-index: 3;
    @media (max-width:991px) {
      padding: 1rem 0;
    }
    @include media-breakpoint-only(xl) {
      min-height:100px;
    }
    @include media-breakpoint-only(lg) {
      min-height:100px;
    }
    @include media-breakpoint-only(md) {
      min-height:100px;
    }
  }
  .carousel-item {
    @include gradient-alt;
    background-position: top center;
    background-repeat: repeat-y;
    background-size: inherit;

  }
  .carousel-inner {
    overflow: inherit;
  }
  #home {
    .carousel-item{
      background-image: url('/assets/img/hero/hero-bg.jpg');
    }
  }
}

.icon {
  padding:0;
  border-radius:0;
  width:55px;
  height:55px;
  text-align:center;
  color:white;
  img {
    vertical-align: sub;
    width:100%;
  }
  @include media-breakpoint-only(xs) {
    width:60px;
    height:60px;
  }
}

.price {
  font-size:5rem;
  font-family: 'bebas-kai',sans-serif;
  line-height: 1em;
  overflow: hidden;
  display: block;
}

.modal-price {
  font-size:3rem;
  font-family: 'bebas-kai',sans-serif;
  line-height: 1em;
}

.list-container {
  ul {
    list-style:none;
    li {
      &::before {
        content: url('/assets/img/icons/check-black.svg');
        display: block;
        height: 18px;
        width: 18px;
        top: .8em;
        margin-left: -2em;
        float:left;
      }
    }
  }
}

.card-img-top {
  width:90px;
  height:90px;
  @include media-breakpoint-only(xs) {
    width:75px;
    height:75px;
  }
}

.statistic {
  font-size:4rem;
  font-weight:600;
  font-family: 'bebas-kai',sans-serif;
  sup {
    font-size: 0.4em;
    top: -1em;
    padding-left: 3px;
  }
}

#accordion, #accordion2, #accordion3, #accordion4, #accordion5, #accordion6 {
  width:100% !important;
  button.btn {
    width:100%;
    text-align:left;
    font-weight:500;
    font-size:1.125rem;
    padding:0;
    text-transform:none;
    border:0px;
    text-decoration: none !important;
    box-shadow: none !important;
  }
  button.btn[aria-expanded="true"] {
    &::after {
      content: '-';
      display: block;
      float:right;
    }
  }
  button.btn[aria-expanded="false"] {
    &::after {
      content: '+';
      display: block;
      float:right;
    }
  }
  h2 {
    font-family: 'rubrik-new', sans-serif;
  }
  .card {
    border:0px;
    overflow:inherit;
    .card-header {
      background:none;
      border: 4px solid $primary;
    }
    .card-body {
      border-left:4px solid $primary;
      border-right:4px solid $primary;
      border-bottom:4px solid $primary;
    }
    p:last-child {
      margin-bottom:0;
    }
  }
}

.sidebar-cta {
  background-color: $black;
  z-index:1000;
  color:$white;
  width:350px;
  border-top:1rem solid white !important;
  p {
    font-size:1.125rem !important;
    line-height:2rem !important;
  }
  h4 {
    line-height:1.5rem;
    color:$cta;
  }
  .price {
    font-weight:500;
  }
  @include media-breakpoint-only(sm) {
    width:200px;
  }
  @include media-breakpoint-only(md) {
    width:270px;
    border-top:4px solid white !important;
  }
  @include media-breakpoint-only(lg) {
    width:300px;
  }
}

.mobile-cta {
  background: inherit;
  z-index:1000;
  color:$white;
  width:auto;
  p {
    font-size:1.125rem !important;
    line-height:2rem !important;
  }
  h4 {
    line-height:2rem;
    color:$cta;
  }
  .price {
    font-weight:500;
  }
}

.mobile-space {
  height:75px;
}

ul {
  &.square-list {
    padding-left:1.5rem;
    li {
      list-style: square;
    }
  }
}

.affix-top {
  margin-top:40px;
  position:absolute;
}

.contact-top {
  margin-top:40px;
  position:absolute;
}


// Section Titles
.section h2.title {
    color: $secondary;
    font-size: 2rem;
    font-weight: 700;
    padding-bottom:0.5rem;
    text-transform: uppercase;
    text-align: center;
}
.section p.description {
    font-size: 1.3rem;
    line-height: 1.6rem;
    text-align: center;
    color: $secondary;
}

/* ***** Warning Box ***** */

#info .warning-box {
    border:dashed 2px $cta;
    background: $white;

}
#info .warning-box .badge {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.9rem
}
#info .warning-box h3 {
    font-weight: 600;
    font-size: 1.3rem;
}
#info .warning-box p {
    line-height: 1.2rem;
}

.flex-column.card {
    height: 100%;
}
.card.branded {
    background: $white;
    border: solid 1px $primary;
}

.payment.panel ul {
    margin:0;
    padding-left: 1.2rem;
}
.payment.panel ul li {
    margin-left: 0;
    padding-left: 0;
}

.payment .symbol {
    font-size: 1.1rem;
    font-weight: 200;
    position: relative;
    top: 1.1rem;
}
.payment .value {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: -1px;
}
.payment .value::before {
    content: "$";
    font-size: 1rem;
    font-weight: 200;
    vertical-align: top;
    display: inline-block;
    line-height: normal;
    padding-right: 0.1rem;
}

.payment .value.gbp3::before,
.payment .value.gbp::before {
    content: "£";
    font-size: 1rem;
    font-weight: 200;
    vertical-align: top;
    display: inline-block;
    line-height: normal;
    padding-right: 0.1rem;
}

.payment .value::after {
    font-size: 1rem;
    font-weight: 200;
    vertical-align: top;
    display: inline-block;
    line-height: normal;
    // padding-left: 0.25rem;
}
.payment .usd.value::after {
    content: ".95";
}
.payment .aud.value::after {
    content: ".95";
}
.payment .cad.value::after {
    content: ".95";
}
.payment .gbp.value::after {
    content: ".95";
}

.payment .usd3.value::after {
    content: ".85";
}
.payment .aud3.value::after {
    content: ".85";
}
.payment .cad3.value::after {
    content: ".85";
}
.payment .gbp3.value::after {
    content: ".85";
}

.payment .subscription {
    font-weight: 200;
    font-size: 0.8rem;

}
/*
##### Feature Content (Info & FAQ) #####
*/

h3.feature.title,
h3.feature.title u {
    color: $blue;
}
h3.feature.title {

    font-size: 1.3em;
    font-weight: 600;
    margin:0;
    padding:0;
}
p.feature.body {
    line-height: 1.2rem;
}

/* ##### END ##### */



/* ##### Comparison Table ##### */
#compare .swipe.out {
    position: relative;
    display:block;
}
#compare .swipe.in {
    vertical-align: middle;
    text-align: center;

}
#compare .swipe .text {
    text-transform: uppercase;
    font-size:0.7rem;
    text-align: center;
    color: $blue;
}
#compare .swipe .image {
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../assets/swc/img/icons/swipe.svg);
    height: 2rem;
    animation-duration: 2.5s;
    animation-delay: 0.5s;
    animation-iteration-count: 1;
}

.modal-dialog {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  @media(max-width: 768px) {
    min-height: calc(100vh - 20px);
  }
}

.modal-content {
  box-shadow: none;
  outline: 0;
  &.border {
    border-color:$primary !important;
  }
  .modal-header {
    background: $primary;
    .modal-title {
      color:$white;
    }
  }
  .modal-footer {
    display:block;
  }
  hr {
    border:3px solid $primary;
  }
  #nsw-alert {
    display:none;
  }
}

.close {
  font-size: 3rem;
  color: $white !important;
  text-shadow: none;
  opacity: 1;
  &:focus, &:hover, &:active {
    box-shadow:0 0 0 0 !important;
    outline:0;
  }
}

.card-columns {
    @include media-breakpoint-only(xs) {
        column-count: 1;
    }
    @include media-breakpoint-only(sm) {
        column-count: 1;
    }
    @include media-breakpoint-only(md) {
        column-count: 2;
    }
    @include media-breakpoint-only(lg) {
        column-count: 3;
    }
    @include media-breakpoint-only(xl) {
        column-count: 3;
    }
}

address {
    margin-bottom: 0.5rem;
}

#coupon-form,
#stripe-checkout-form {
    border: none;
}
.fsBody {
  padding:0 !important;
  .fsForm {
    padding: 0 !important;
    font-size:18px !important;
    h2 {
      font-size: 2rem !important;
    }
    .fsSectionHeading {
      font-size: 1.5rem !important;
    }
  }
  .fsSubmit input.fsSubmitButton, .fsSubmit input.fsSubmitMultipage {
    float: left;
    background: linear-gradient(to bottom,$cta,$cta);
    border: solid 1px $cta;
    border-top: solid 1px $cta;
    border-bottom: solid 1px $cta;
    padding: 1.35rem 2rem;
    &:hover {
      background: linear-gradient(to bottom,lighten($cta, 10%),lighten($cta, 10%));
      border: solid 1px lighten($cta, 10%);
      border-top: solid 1px lighten($cta, 10%);
      border-bottom: solid 1px lighten($cta, 10%);
    }
  }
}

footer {
  .contact {
    font-size:1rem;
    line-height:1.5rem;
  }
  a {
    font-weight:200;
  }
}
